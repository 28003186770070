import React from "react";
import {
    currentCrumb,
    extractSelectedLanguage,
    linkCrumb,
    LoadingIndicator,
    NavigationList,
    Segment,
} from "@ingka-livlig/frontend-lib";

import MetricsPage from "./MetricsPage";
import useApiRequest from "./useApiRequest";
import styles from "./MetricsRole.module.css";
import { useParams } from "react-router-dom";
import { LocationType } from "./api";
import { Trans } from "@lingui/macro";

export const MetricsMostViewedPage: React.FC = () => {
    const { locationType, locationId } = useParams<{
        locationType: LocationType;
        locationId: string;
    }>();

    const metricsMostViewedPageQuery =
        locationType == "Global" || locationType == "GlobalOnly"
            ? useApiRequest("globalMetricsQuery", locationType)
            : useApiRequest("metricsQuery", locationType, locationId);

    const locationName =
        metricsMostViewedPageQuery.tag == "success" && (locationType === "Country" || locationType === "CountryOnly")
            ? metricsMostViewedPageQuery.value.countryName
            : metricsMostViewedPageQuery.tag == "success" && locationType === "SellingUnit"
            ? metricsMostViewedPageQuery.value.sellingUnitScope?.name
            : "Global";

    const crumbs = () => {
        if (metricsMostViewedPageQuery.tag == "success") {
            switch (locationType) {
                case "Global":
                    return [linkCrumb(`Global`, `/locations/global`), currentCrumb(`Most Viewed Pages`)];
                case "GlobalOnly":
                    return [linkCrumb(`GlobalOnly`, `/locations/GlobalOnly`), currentCrumb(`Most Viewed Pages`)];
                case "Country":
                    return [
                        linkCrumb(`Global`, `/locations/global`),
                        linkCrumb(
                            `${metricsMostViewedPageQuery.value.countryName}`,
                            `/locations/Country/${locationId}/`,
                        ),
                        currentCrumb(`Most Viewed Pages`),
                    ];
                case "CountryOnly":
                    return [
                        linkCrumb(`Global`, `/locations/global`),
                        linkCrumb(
                            `${metricsMostViewedPageQuery.value.countryName}`,
                            `/locations/CountryOnly/${locationId}/`,
                        ),
                        currentCrumb(`Most Viewed Pages`),
                    ];
                case "SellingUnit":
                    return [
                        linkCrumb(`Global`, `/locations/global`),
                        linkCrumb(
                            `${metricsMostViewedPageQuery.value.sellingUnitScope?.countryScope.name}`,
                            `/locations/Country/${metricsMostViewedPageQuery.value.sellingUnitScope?.countryScope.countryCode}/`,
                        ),
                        linkCrumb(
                            `${metricsMostViewedPageQuery.value.sellingUnitScope?.name}`,
                            `/locations/SellingUnit/${metricsMostViewedPageQuery.value.sellingUnitScope?.suCode}/`,
                        ),
                        currentCrumb(`Most Viewed Pages`),
                    ];
            }
        }
    };

    const locale = extractSelectedLanguage();

    function renderContent() {
        switch (metricsMostViewedPageQuery.tag) {
            case "loading":
                return <LoadingIndicator />;
            case "error":
                return (
                    <p className={styles.metric_message}>
                        <Trans>Failed to fetch the Metrics Data</Trans>
                    </p>
                );
            case "success":
                return (
                    <>
                        <div className={styles.metricsInfo}>
                            <div className={styles.mainTitle}>
                                {locationName}
                                <br /> <Trans>Most Viewed Pages</Trans>
                                <h5>
                                    <Trans>Last 35 days</Trans>
                                </h5>
                            </div>
                        </div>

                        <div>
                            {metricsMostViewedPageQuery.value.featurePageViews.map(({ feature, pageView }, index) => (
                                <NavigationList key={index}>
                                    <div className={styles.entry}>
                                        <div className={styles.indexSmall}>{index + 1}</div>
                                        <span className={styles.countryView}>{feature}</span>
                                        <span className={styles.pageView}>{pageView.toLocaleString(locale)}</span>
                                    </div>
                                </NavigationList>
                            ))}
                        </div>
                    </>
                );
        }
    }

    return (
        <MetricsPage breadcrumbItems={crumbs()}>
            <Segment variant="primary" padded>
                {renderContent()}
            </Segment>
        </MetricsPage>
    );
};
