import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Global } from "./Global";
import {
    AppBase,
    ScrollToTop,
    sendEventAndRoute,
    TranslatePage,
    useEvents,
    UserNavigationEvent,
} from "@ingka-livlig/frontend-lib";
import { QueryParamProvider } from "use-query-params";
import { localeActivate } from "./i18n";
import { withStore } from "react-context-hook";
import NotFound from "./NotFound";
import { useAPI } from "./api";
import React from "react";
import { MetricsCountry } from "./MetricsCountry";
import { MetricsSellingUnit } from "./MetricsSellingUnit";
import { MetricsRole } from "./MetricsRole";
import { MetricsMostViewedPage } from "./MetricsMostViewedPage";
import { MetricsHfbPage } from "./MetricsHfbPage";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { useLingui } from "@lingui/react";
import * as englishMessages from "./locales/en/messages.mjs";

const Translate = () => {
    const api = useAPI();
    return (
        <TranslatePage
            recordTranslation={api.recordTranslation}
            fetchEnglishTransations={() => englishMessages.messages}
        />
    );
};

function Routes() {
    const events = useEvents<UserNavigationEvent>(useAPI());
    /**
     * This is put here in order to force Lingui to remount when language changes.
     * With Lingui 4 the default is to only rerender when language us changed. This works nicely when <Trans> elements
     * are used, but doesn't work when the t`...` templated string is used. The result is a lot of untranslated strings.
     * We handle this by remounting everything, which shouldn't be an issue as it's expected that users seldomly
     * change languages.
     *
     * In addition, we rely on the selected locale to fetch proper names of HFBs and PAs from the server, so we need to
     * trigger a refetch anyway.
     */
    useLingui();

    return (
        <Switch>
            <Route exact path="/translate" render={sendEventAndRoute("Translate", Translate, events)} />
            <Route exact path="/" render={sendEventAndRoute("Root", Global, events)} />
            <Route
                exact
                path="/locations/:locationType(Country|CountryOnly)/:locationId"
                render={sendEventAndRoute("MetricsCountry", MetricsCountry, events)}
            />
            <Route
                exact
                path="/locations/:locationType(Global|GlobalOnly)"
                render={sendEventAndRoute("Global", Global, events)}
            />
            <Route
                exact
                path="/locations/:locationType(Global|GlobalOnly)/role"
                render={sendEventAndRoute("MetricsRole", MetricsRole, events)}
            />
            <Route
                exact
                path="/locations/:locationType(Global|GlobalOnly)/HFB"
                render={sendEventAndRoute("MetricsHfbPage", MetricsHfbPage, events)}
            />
            <Route
                exact
                path="/locations/:locationType(Global|GlobalOnly)/feature"
                render={sendEventAndRoute("MetricsMostViewedPage", MetricsMostViewedPage, events)}
            />
            <Route
                exact
                path="/locations/:locationType/:locationId"
                render={sendEventAndRoute("MetricsSellingUnit", MetricsSellingUnit, events)}
            />
            <Route
                exact
                path="/locations/:locationType/role"
                render={sendEventAndRoute("MetricsRole", MetricsRole, events)}
            />
            <Route
                exact
                path="/locations/:locationType/:locationId/role"
                render={sendEventAndRoute("MetricsRole", MetricsRole, events)}
            />
            <Route
                exact
                path="/locations/:locationType/:locationId/feature"
                render={sendEventAndRoute("MetricsMostViewedPage", MetricsMostViewedPage, events)}
            />
            <Route
                exact
                path="/locations/:locationType/:locationId/HFB"
                render={sendEventAndRoute("MetricsHfbPage", MetricsHfbPage, events)}
            />
            <Route path="/" render={sendEventAndRoute("NotFound", NotFound, events)} />
        </Switch>
    );
}

function MainRouter() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <AppBase localeActivate={localeActivate}>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                    <Routes />
                </QueryParamProvider>
            </AppBase>
        </BrowserRouter>
    );
}

export default withStore(MainRouter, {});
